<template>
  <div class="app-container">
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="1080px"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-tabs v-if="open" v-model="activeTab" tab-position="left">
        <el-tab-pane label="基本配置" name="basic" :lazy="true">
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="名称" prop="name">
                  <el-input v-model="form.name" placeholder="请输入名称" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="数据表名称" prop="extract_table">
                  <el-input v-model="form.extract_table" placeholder="请输入数据表名称" :disabled="form.id > 0" />
                </el-form-item>
              </el-col>
              <!--<el-col :span="8">
                                <el-form-item label="数据库" prop="database_id">
                                    <treeselect
                                        v-model="form.database_id"
                                        :disable-branch-nodes="true"
                                        :options="databaseTree"
                                        placeholder="请选择数据库连接"
                                        :normalizer="normalizerDatabaseNode"
                                    />
                                </el-form-item>
                            </el-col>-->
              <el-col :span="8">
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="form.remark"
                    type="textarea"
                    placeholder="请输入备注"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="主键" prop="primary_key">
                  <el-input v-model="form.primary_key" placeholder="请输入主键" :disabled="form.id > 0" />
                </el-form-item>
              </el-col>
              <!--<el-col :span="8">
                <el-form-item label="索引" prop="index_keys">
                  <el-input v-model="form.index_keys" placeholder="请输入索引" />
                </el-form-item>
              </el-col>-->
              <el-col :span="8">
                <el-form-item label="数据保存方式" prop="save_type">
                  <el-select v-model="form.save_type" placeholder="请选择">
                    <el-option label="添加数据" value="0"></el-option>
                    <el-option label="更新数据" value="1"></el-option>
                    <el-option label="清空并添加数据" value="2"></el-option>
                    <el-option label="更新并添加数据" value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="form.save_type=='1' || form.save_type=='3'" :span="8">
                <el-form-item label="唯一字段" prop="uniq_fields">
                  <el-input v-model="form.uniq_fields" placeholder="请输入唯一字段" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <!-- <vxe-grid
                                    ref="fieldsGrid"
                                    row-key
                                    max-height="290"
                                    border
                                    class="sortable-row-demo"
                                    :columns="fieldsColumn"
                                    :data="this.fields"
                                    :edit-config="{
                                        trigger: 'click',
                                        mode: 'cell',
                                    }"
                                    :toolbar-config="tableToolbar1"
                                >
                                    <template #toolbar_buttons>
                                        <el-button
                                            type="primary"
                                            icon="el-icon-plus"
                                            size="mini"
                                            @click="addParam"
                                            >添加参数</el-button
                                        >
                                    </template>
                                    <template #fieldsdefaultopr="{ row }">
                                        <el-button
                                            size="mini"
                                            type="text"
                                            icon="el-icon-delete"
                                            @click="handleDeleteParam(row)"
                                            >删除
                                        </el-button>
                                    </template>
                                </vxe-grid> -->
                <jf-table
                  ref="fieldsGrid"
                  :max-height="400"
                  :checkbox="false"
                  class="sortable-row-demo"
                  :columns="form.id ? fieldsColumnEdit : fieldsColumn"
                  :defaultopr-width="80"
                  :columns-btn="false"
                  :filter-btn="false"
                  :sort-btn="false"
                  :pager="false"
                  :data="this.fields"
                  :edit-config="{
                    trigger: 'click',
                    mode: 'cell',
                  }"
                  :toolbar-config="tableToolbar1"
                >
                  <template slot="toolbar_btn_left">
                    <!-- <el-button
                                            type="primary"
                                            icon="el-icon-plus"
                                            size="mini"
                                            @click="addParam"
                                           
                                            >导入Excel文件</el-button
                                        > -->
                    <el-upload
                      action="#"
                      :auto-upload="true"
                      :multiple="false"
                      :show-file-list="false"
                      :before-upload="upload"
                      accept=".xlsx,.xls"
                    >
                      <el-button
                        type="primary"
                        icon="el-icon-plus"
                        :loading="divLoading"
                        size="mini"
                        >导入Excel文件</el-button
                      >
                      &nbsp;<span :style="importList.length == 0 ? 'color: #ccc' : ''">{{excelFileName}}</span>
                    </el-upload>
                  </template>
                  <template slot="toolbar_btn_row" slot-scope="{ row }">
                    <el-button
                      size="mini"
                      type="text"
                      icon="el-icon-delete"
                      @click="handleDeleteParam(row)"
                      >删除
                    </el-button>
                  </template>
                  <template slot="default_sort" slot-scope="{ row }">
                    <span class="drag-btn">
                      <i class="vxe-icon--menu"></i>
                    </span>
                  </template>
                </jf-table>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="数据列配置" name="meta" :lazy="true">
          <!-- <vxe-grid
                        v-if="activeTab == 'meta'"
                        ref="metasGrid"
                        row-key
                        max-height="400"
                        border
                        class="sortable-row-demo"
                        :columns="metasColumn"
                        :data="form.metas"
                        :edit-config="{ trigger: 'click', mode: 'cell' }"
                        :toolbar-config="tableToolbar1"
                    >
                        <template #toolbar_buttons>
                            <el-button
                                type="primary"
                                icon="el-icon-refresh"
                                size="mini"
                                @click="analysisMeta"
                                >解析</el-button
                            >
                        </template>
                        <template #display_type="{ row }">
                            <el-select
                                v-model="row.display_type"
                                placeholder="请选择"
                            >
                                <el-option label="文本" value=""></el-option>
                                <el-option
                                    label="图片"
                                    value="image"
                                ></el-option>
                                <el-option
                                    label="二维码"
                                    value="qrcode"
                                ></el-option>
                                <el-option
                                    label="链接"
                                    value="link"
                                ></el-option>
                            </el-select>
                        </template>
                        <template #parse_type="{ row }">
                            <el-select
                                v-model="row.parse_type"
                                placeholder="请选择"
                            >
                                <el-option label="无" value=""></el-option>
                                <el-option
                                    label="自循环"
                                    value="self_cycle"
                                ></el-option>
                                <el-option
                                    label="自循环（逗号分割）"
                                    value="self_cycle_,"
                                ></el-option>
                                <el-option
                                    label="自循环（分号分割）"
                                    value="self_cycle_;"
                                ></el-option>
                                <el-option
                                    label="自循环（json）"
                                    value="self_cycle_json"
                                ></el-option>
                            </el-select>
                        </template>
                        <template #metasdefaultopr="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleDeleteMeta(row)"
                                >删除
                            </el-button>
                        </template>
                    </vxe-grid> -->
          <jf-table
            v-if="activeTab == 'meta'"
            ref="metasGrid"
            :max-height="400"
            class="sortable-row-demo"
            :columns="metasColumn"
            :data="form.metas"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
            :toolbar-config="tableToolbar1"
            :defaultopr-width="80"
            :checkbox="false"
            :columns-btn="false"
            :filter-btn="false"
            :sort-btn="false"
            :pager="false"
          >
            <template slot="toolbar_btn_left">
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="mini"
                @click="analysisMeta"
                >解析</el-button
              >
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDeleteMeta(row)"
                >删除
              </el-button>
            </template>
            <template slot="parse_type" slot-scope="{ row }">
              <el-select v-model="row.parse_type" placeholder="请选择">
                <el-option label="无" value=""></el-option>
                <el-option label="自循环" value="self_cycle"></el-option>
                <el-option
                  label="自循环（逗号分割）"
                  value="self_cycle_,"
                ></el-option>
                <el-option
                  label="自循环（分号分割）"
                  value="self_cycle_;"
                ></el-option>
                <el-option
                  label="自循环（json）"
                  value="self_cycle_json"
                ></el-option>
              </el-select>
            </template>
            <template slot="display_type" slot-scope="{ row }">
              <el-select v-model="row.display_type" placeholder="请选择">
                <el-option label="文本" value=""></el-option>
                <el-option label="图片" value="image"></el-option>
                <el-option label="二维码" value="qrcode"></el-option>
                <el-option label="链接" value="link"></el-option>
              </el-select>
            </template>
            <template slot="type" slot-scope="{ row }">
              {{ typeFormat({ row }) }}
            </template>
            <template slot="default_sort" slot-scope="{ row }">
              <span class="drag-btn">
                <i class="vxe-icon--menu"></i>
              </span>
            </template>
          </jf-table>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button style="float: left" @click="previewData">预览数据</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="预览数据"
      :visible.sync="openPreview"
      width="960px"
      append-to-body
    >
      <!-- <vxe-grid
                show-overflow
                ref="previewGrid"
                max-height="400"
                border
                :columns="previewColumn"
                :data="previewList"
                :toolbar-config="tableToolbar2"
            >
            </vxe-grid> -->
      <jf-table
        ref="previewGrid"
        :max-height="400"
        :checkbox="false"
        :columns="previewColumn"
        :data="previewList"
        :toolbar-config="tableToolbar2"
        :columns-btn="false"
        :filter-btn="false"
        :sort-btn="false"
        :pager="false"
        :operate="false"
      >
      </jf-table>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closePreview">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getModel,
  addModel,
  updateModel,
  previewModel,
} from "@/api/core/data/datasource/model";
import { getDatabaseTree } from "@/api/core/data/datasource/database";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Loading } from "element-ui";
import Sortable from "sortablejs";
import { read, utils } from "xlsx";

export default {
  name: "add-model",
  components: { Treeselect },
  data() {
    return {
      tableToolbar1: {
        perfect: false,
        zoom: true,
        custom: false,
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      tableToolbar2: {
        perfect: false,
        zoom: true,
        custom: false,
      },
      metasColumn: [
        { field: "index", title: "序号", width: 50, align: "center" },
        { field: "name", title: "字段名" },
        {
          field: "type",
          title: "类型",
          width: 60,
          // formatter: this.typeFormat,
          slots: { default: "type" },
        },
        {
          field: "display_type",
          title: "展示方式",
          width: 130,
          slots: { default: "display_type" },
        },
        {
          field: "parse_type",
          title: "解析方式",
          width: 180,
          slots: { default: "parse_type" },
        },
        {
          field: "label",
          title: "标签",
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入标签" },
          },
        },
        {
          field: "dict",
          title: "数据字典",
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入数据字典" },
          },
        },
        //{field: 'sort', title: '排序', editRender: {name: 'input', attrs: {type: 'number', placeholder: '请输入排序'}}},
        {
          field: "sort",
          title: "排序",
          width: 60,
          align: "center",
          slots: {
            default: "default_sort",
            // () => {
            //     return [
            //         <span class="drag-btn">
            //             <i class="vxe-icon--menu"></i>
            //         </span>,
            //     ];
            // },
          },
        },
        //{type: 'checkbox', width: 60 }
        // {
        //     field: "",
        //     title: "操作",
        //     resizable: false,
        //     width: 80,
        //     fixed: "right",
        //     align: "center",
        //     slots: { default: "metasdefaultopr" },
        // },
      ],
      fieldsColumn: [
        { field: "index", title: "序号", width: 60, align: "center", fixed: "left"},
        {
          field: "name",
          title: "excel字段",
          width: 120,
          fixed: "left",
          // editRender: {
          //     name: "input",
          //     attrs: { type: "text", placeholder: "请输入参数名" },
          // },
        },
        //{field: 'type', title: '类型', editRender: {name: 'select', options: [{label: '', value: ''}, {label: 'string', value: 'string'}, {label: 'number', value: 'number'}]} },
        //{field: 'label', title: '标签', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入标签'}}},
        {
          field: "field",
          title: "数据表字段",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入数据表字段" },
          },
        },
        {
          field: "type",
          title: "字段类型",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入字段类型" },
          },
        },
        {
          field: "size",
          title: "字段长度",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入字段长度" },
          },
        },
        {
          field: "dict",
          title: "数据字典",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入数据字典" },
          },
        },
        {
          field: "timestamp_format",
          title: "格式化时间戳",
          width: 130,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "格式化时间戳" },
          },
        },
        {
          field: "join_table",
          title: "关联数据表",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入关联数据表" },
          },
        },
        {
          field: "join_table_label_field",
          title: "标签字段",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入关联数据表标签字段" },
          },
        },
        {
          field: "join_table_value_field",
          title: "值字段",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入关联数据表值字段" },
          },
        },
        //{field: 'sort', title: '排序', editRender: {name: 'input', attrs: {type: 'number', placeholder: '请输入排序'}}},
        {
          field: "sort",
          title: "排序",
          width: 60,
          align: "center",
          // slots: {
          //     default: () => {
          //         return [
          //             <span class="drag-btn">
          //                 <i class="vxe-icon--menu"></i>
          //             </span>,
          //         ];
          //     },
          // },
          slots: {
            default: "default_sort",
            // () => {
            //     return [
            //         <span class="drag-btn">
            //             <i class="vxe-icon--menu"></i>
            //         </span>,
            //     ];
            // },
          },
        },
        // {
        //     field: "",
        //     title: "操作",
        //     resizable: false,
        //     width: 80,
        //     fixed: "right",
        //     align: "center",
        //     slots: { default: "fieldsdefaultopr" },
        // },
      ],
      fieldsColumnEdit: [
        { field: "index", title: "序号", width: 60, align: "center", fixed: "left"},
        {
          field: "name",
          title: "excel字段",
          width: 120,
          fixed: "left",
          // editRender: {
          //     name: "input",
          //     attrs: { type: "text", placeholder: "请输入参数名" },
          // },
        },
        //{field: 'type', title: '类型', editRender: {name: 'select', options: [{label: '', value: ''}, {label: 'string', value: 'string'}, {label: 'number', value: 'number'}]} },
        //{field: 'label', title: '标签', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入标签'}}},
        {
          field: "field",
          title: "数据表字段",
          width: 120,
        },
        {
          field: "type",
          title: "字段类型",
          width: 120,
        },
        {
          field: "size",
          title: "字段长度",
          width: 120,
        },
        {
          field: "dict",
          title: "数据字典",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入数据字典" },
          },
        },
        {
          field: "timestamp_format",
          title: "格式化时间戳",
          width: 130,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "格式化时间戳" },
          },
        },
        {
          field: "join_table",
          title: "关联数据表",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入关联数据表" },
          },
        },
        {
          field: "join_table_label_field",
          title: "标签字段",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入关联数据表标签字段" },
          },
        },
        {
          field: "join_table_value_field",
          title: "值字段",
          width: 120,
          editRender: {
            name: "input",
            attrs: { type: "text", placeholder: "请输入关联数据表值字段" },
          },
        },
        //{field: 'sort', title: '排序', editRender: {name: 'input', attrs: {type: 'number', placeholder: '请输入排序'}}},
      ],
      previewColumn: [],
      previewList: [],
      activeTab: "basic",
      loading: true,
      title: "",
      open: false,
      openPreview: false,
      databaseTree: [],
      form: {
        metas: [],
        params: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
        database_id: [
          {
            required: true,
            message: "请选择数据库连接",
            trigger: "blur",
          },
        ],
        sql: [
          {
            required: true,
            message: "sql语句不能为空",
            trigger: "blur",
          },
        ],
        save_type: [
          {
            required: true,
            message: "数据保存方式不能为空",
            trigger: "blur",
          },
        ],
        uniq_fields: [
          {
            required: true,
            message: "唯一字段不能为空",
            trigger: "blur",
          },
        ],
      },
      excelFileName: "",
      worksheet: [],
      importColumns: [],
      importList: [],
      fields: [],
      divLoading: false,
    };
  },
  created() {
    getDatabaseTree().then((response) => {
      if (response.code == 0) {
        this.databaseTree = response.data;
      }
    });
  },
  methods: {
    rowDropFields() {
      this.$nextTick(() => {
        if (this.sortableFields) {
          return;
        }
        const fieldsGrid = this.$refs.fieldsGrid;
        const xTable = fieldsGrid.$refs.xGrid;
        console.log(xTable, "xTable", this.$refs.fieldsGrid);
        this.sortableFields = Sortable.create(
          xTable.$el.querySelector(".body--wrapper>.vxe-table--body tbody"),
          {
            handle: ".drag-btn",
            onEnd: ({ newIndex, oldIndex }) => {
              const currRow = this.fields.splice(oldIndex, 1)[0];
              this.fields.splice(newIndex, 0, currRow);
              for (let i = 0; i < this.fields.length; i++) {
                this.fields[i].sort = i + 1;
              }
            },
          }
        );
      });
    },
    rowDropMetas() {
      this.$nextTick(() => {
        if (this.sortableMetas) {
          return;
        }
        const xTable = this.$refs.metasGrid;
        this.sortableMetas = Sortable.create(
          xTable.$el.querySelector(".body--wrapper>.vxe-table--body tbody"),
          {
            handle: ".drag-btn",
            onEnd: ({ newIndex, oldIndex }) => {
              const currRow = this.form.metas.splice(oldIndex, 1)[0];
              this.form.metas.splice(newIndex, 0, currRow);
              for (let i = 0; i < this.form.metas.length; i++) {
                this.form.metas[i].sort = i + 1;
              }
            },
          }
        );
      });
    },
    normalizerDatabaseNode(e) {
      e.label = e.name;
      return e;
    },
    cancel() {
      this.previewColumn = [];
      this.previewList = [];
      this.open = false;
      this.reset();
    },
    reset() {
      this.form = {
        id: undefined,
        pid: 0,
        type: "5",
        mode: "0",
        name: "",
        database_id: undefined,
        sql: "",
        ext: "",
        extract_table: "",
        primary_key: "",
        index_keys: "",
        save_type: "",
        uniq_fields: "",
        remark: undefined,
        metas: [],
        params: [],
      };
      this.excelFileName = ""
      this.fields = []
      this.worksheet = []
      this.importList = []
      this.importColumns = []
      this.resetForm("form");
    },
    add(mode, pid) {
      this.reset();
      this.form.mode = mode;
      this.form.pid = pid;
      this.activeTab = "basic";
      this.open = true;
      this.title = "添加Excel数据集（" + (mode == "0" ? "实时" : "抽取") + "）";
    },
    update(id) {
      this.reset();
      getModel(id).then((response) => {
        //this.form = response.data;
        let data = response.data;
        if (data) {
          this._sql_ = data.sql;
          for (let key in data) {
            this.form[key] = data[key];
          }

          const ext = JSON.parse(data.ext)
          this.form.primary_key = ext.primary_key
          this.form.index_keys = ext.index_keys
          this.form.save_type = ext.save_type
          this.form.uniq_fields = ext.uniq_fields
          this.excelFileName = ext.excelFileName
          this.fields = ext.fields

          for (let i = 0; i < this.form.metas.length; i++) {
            const m = this.form.metas[i];
            m.index = i + 1;
            m.sort = i + 1;
          }

          for (let i = 0; i < this.form.params.length; i++) {
            const p = this.form.params[i];
            p.index = i + 1;
            p.sort = i + 1;
          }

          this.activeTab = "basic";
          this.open = true;
          this.title =
            "修改Excel数据集（" + (data.mode == "0" ? "实时" : "抽取") + "）";
        }
      });
    },
    submitForm: function () {
      //console.log(this.form)
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.importList || this.importList.length == 0) {
            this.msgError('请导入excel文件')
            return
          }
          if (this.form.save_type == '2') {
            this.$confirm('清空数据将无法恢复，确定清空数据并添加新数据吗?', "警告", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              //if (!this.form.metas || this.form.metas.length == 0) {
                this.analysisMeta().then(() => {
                  this.doSubmitForm();
                });
              //} else {
              //  this.doSubmitForm();
              //}
            })
          } else {
            //if (!this.form.metas || this.form.metas.length == 0) {
              this.analysisMeta().then(() => {
                this.doSubmitForm();
              });
            //} else {
            //  this.doSubmitForm();
            //}
          }
        }
      });
    },
    doSubmitForm: function () {
      let ext = {
        primary_key: this.form.primary_key,
        index_keys: this.form.index_keys,
        save_type: this.form.save_type,
        uniq_fields: this.form.uniq_fields,
        excelFileName: this.excelFileName,
        fields: this.fields,
        rows: this.importList
      }
      const form = {
        ...this.form,
        ext: JSON.stringify(ext),
      }
      const loading = this.$loading({
        text: '正在提交...'
      })
      if (this.form.id != undefined) {
        updateModel(form).then((response) => {
          loading.close()
          if (response.code === 0) {
            this.msgSuccess("修改成功");
            this.previewColumn = [];
            this.previewList = [];
            this.open = false;
            this.$emit("change", response.data);
          } else {
            this.msgError(response.msg);
          }
        }).catch(e => {
          loading.close()
        });
      } else {
        addModel(form).then((response) => {
          loading.close()
          if (response.code === 0) {
            this.msgSuccess("新增成功");
            this.previewColumn = [];
            this.previewList = [];
            this.open = false;
            this.$emit("change", response.data);
          } else {
            this.msgError(response.msg);
          }
        }).catch(e => {
          loading.close()
        });
      }
    },
    typeFormat({ row }) {
      if (row.type == "number") {
        return "数值";
      } else {
        return "文本";
      }
    },
    previewData() {
      if (!this.fields || this.fields.length == 0 || !this.importList || this.importList.length == 0) {
        this.msgError('请导入excel文件')
        return
      }
      let keys = Object.keys(this.importList[0]);
      const previewColumn = [];
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        let t = ""
        for (let j = 0; j < this.fields.length; j++) {
          const f = this.fields[j]
          if (f.name == k) {
            t = f.field || f.name
            break
          }
        }
        previewColumn.push({
          field: k,
          title: t,
          minWidth: 150,
        });
      }
      this.previewColumn = previewColumn;
      this.previewList = JSON.parse(JSON.stringify(this.importList)); //.splice(0, 1);
      this.openPreview = true;
    },
    analysisMeta() {
      return new Promise((resolve, reject) => {
        if (!this.fields || this.fields.length == 0 || !this.importList || this.importList.length == 0) {
          reject()
          return
        }
        const d = this.importList[0]
        const metas = [];
        let index = 1;
        for (let j = 0; j < this.fields.length; j++) {
          const f = this.fields[j]
          let k = f["field"]
          if (!k) {
            k = f["name"]
          }
          const v = d[k];
          let oldMeta = null;
          for (let i = 0; i < this.form.metas.length; i++) {
            const m = this.form.metas[i];
            if (m.name == k) {
              oldMeta = m;
              break;
            }
          }
          metas.push({
            index: index,
            name: k,
            type: typeof v,
            parse_type: oldMeta && oldMeta.parse_type !== undefined ? oldMeta.parse_type : undefined,
            display_type: oldMeta && oldMeta.display_type !== undefined ? oldMeta.display_type : undefined,
            label: (oldMeta && oldMeta.label) || f["name"] || "",
            dict: (oldMeta && oldMeta.dict) || f["dict"] || "",
            sort: index, //oldMeta && oldMeta.sort || 0
          });
          index++;
        }
        this.form.metas = metas;
        resolve()
      });
    },
    closePreview() {
      this.openPreview = false;
    },
    handleDeleteMeta(row) {
      this.$confirm('确定删除序号为"' + row.index + '"的数据列?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const metas = [];
          let index = 1;
          for (let i = 0; i < this.form.metas.length; i++) {
            const m = this.form.metas[i];
            if (m.index != row.index) {
              m.index = index++;
              m.sort = m.index;
              metas.push(m);
            }
          }
          this.form.metas = metas;
        })
        .catch(function () {});
    },
    addParam() {
      // this.fields.push({
      //     index: this.fields.length + 1,
      //     name: "param" + (this.fields.length + 1),
      //     default_value: "",
      //     sort: 0,
      // });
    },
    //上传
    upload(file, fileList) {
      let files = [file];
      let imgSize = Number(file.size / 1024 / 1024);
      if (files.length <= 0) {
        this.$msgbox({
          title: "",
          message: "上传失败请重新上传",
          type: "warning",
        });
        return false;
      }
      if (imgSize > 10) {
        this.$msgbox({
          title: "",
          message: "文件大小不能超过10MB，请重新上传。",
          type: "warning",
        });
        return false;
      }
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
        return;
      }
      this.excelFileName = file.name
      this.readExcel1(files);
      return false; //false表示不需要上传~
    },
    // 表格导入
    readExcel1(files) {
      var that = this;
      this.divLoading = true;
      // this.loading_text = "上传中~";
      // 此处判断不严谨，思路只是根据传入的参数校验数据的完整性，可根据自己需求修改
      // 如果没有文件名

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;

          const workbook = read(data, {
            type: "binary",
            cellDates: false,
          });

          this.worksheet = [];
          //console.log(workbook);
          // 生成json表格内容
          workbook.SheetNames.forEach((wsname) => {
            let ws = utils.sheet_to_json(workbook.Sheets[wsname], {raw: false});
            let obj = {
              wsname: wsname,
              ws: ws,
            };
            this.worksheet.push(obj);
          });

          // 后续为自己对ws数据的处理

          this.getImportList(this.worksheet[0].ws, 1);
        } catch (e) {
          this.divLoading = false;
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    getImportList(list, type) {
      let length = 0;
      let maxIndex = 0;
      // if (type * 1 === 2) {
      //     this.divLoading = true;
      //     // this.loading_text = "加载中~";
      // }
      if (list.length > 5000) {
        this.divLoading = false;
        this.$msgbox({
          title: "",
          message: "单个sheet页数据量不超过5000行,请切换工作表或重新上传",
          type: "warning",
        });
        return false;
      }
      // 第1行为header
      // list.forEach((item, index) => {
      //     if (Object.keys(item).length > length) {
      //         length = Object.keys(item).length;
      //         maxIndex = index;
      //     }
      // });
      if (!this.form.id) {
        this.importColumns = [];
        this.fields = [];
        let array = Object.keys(list[maxIndex]);
        for (let i in array) {
          this.importColumns.push({
            field: array[i],
            title: array[i],
          });
          this.fields.push({
            index: this.fields.length + 1,
            name: array[i],
            field: "",
            type: "",
            size: "",
            dict: "",
            timestamp_format: "",
            join_table: "",
            join_table_label_field: "",
            join_table_value_field: "",
            sort: 0,
          });
        }
      }

      this.importList = list;
      this.divLoading = false;

      this.previewData()
    },

    handleDeleteParam(row) {
      this.$confirm('确定删除序号为"' + row.index + '"的参数?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const fields = [];
          let index = 1;
          for (let i = 0; i < this.fields.length; i++) {
            const p = this.fields[i];
            if (p.index != row.index) {
              p.index = index++;
              p.sort = p.index;
              fields.push(p);
            }
          }
          this.fields = fields;
        })
        .catch(function () {});
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.rowDropFields();
      } else {
        if (this.sortableFields) {
          this.sortableFields.destroy();
          this.sortableFields = null;
        }
        if (this.sortableMetas) {
          this.sortableMetas.destroy();
          this.sortableMetas = null;
        }
      }
    },
    activeTab(val) {
      if (val == "meta") {
        this.analysisMeta()
        this.rowDropMetas();
      } else {
        if (this.sortableMetas) {
          this.sortableMetas.destroy();
          this.sortableMetas = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.sortable-row-demo .drag-btn {
  cursor: move;
  font-size: 12px;
}
.sortable-row-demo .vxe-body--row.sortable-ghost,
.sortable-row-demo .vxe-body--row.sortable-chosen {
  background-color: #dfecfb;
}
</style>
