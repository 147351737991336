import request from '@/utils/request'

// 查询数据库连接列表
export function listDatabase(query) {
  return request({
    url: '/datasource/database/list',
    method: 'get',
    params: query
  })
}

// 查询数据库连接详细
export function getDatabase(id) {
  return request({
    url: '/datasource/database/get?id=' + id,
    method: 'get'
  })
}

export function getDatabaseTree() {
  return request({
    url: '/datasource/database/tree',
    method: 'get'
  })
}

// 新增数据库连接
export function addDatabase(data) {
  return request({
    url: '/datasource/database/add',
    method: 'post',
    data: data
  })
}

// 修改数据库连接
export function updateDatabase(data) {
  return request({
    url: '/datasource/database/edit',
    method: 'post',
    data: data
  })
}

// 删除数据库连接
export function delDatabase(id) {
  return request({
    url: '/datasource/database/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}

// 测试数据库连接
export function testConnect(data) {
  return request({
    url: '/datasource/database/test',
    method: 'post',
    data: data
  })
}
