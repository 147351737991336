<template>
  <div class="app-container">
    <el-dialog :title="title" :visible.sync="open" width="1080px" :close-on-click-modal="false" append-to-body>
      <el-tabs v-model="activeTab" tab-position="left">
        <el-tab-pane label="基本配置" name="basic" :lazy="true">
          <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="名称" prop="name">
                  <el-input v-model="form.name" placeholder="请输入名称" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="请求地址" prop="url">
                  <el-input v-model="form.url" placeholder="请输入地址" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="请求方法" prop="method">
                  <el-select v-model="form.method" placeholder="请选择请求方法">
                    <el-option label="GET" value="GET"></el-option>
                    <el-option label="POST" value="POST"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="内部API" prop="internal">
                  <el-switch
                    v-model="form.internal"
                    active-value="1"
                    inactive-value="0"
                    active-color="#13ce66">
                  </el-switch>
                  <span style="color: rgb(192, 196, 204)">传递身份验证信息</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="请求超时" prop="timeout">
                  <el-input-number v-model="form.timeout" placeholder="请输入请求超时" controls-position="right" />
                  <span style="margin-left: 5px;">毫秒</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="正确字段" prop="success_field">
                  <el-input v-model="form.success_field" placeholder="请输入正确字段，比如code" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="正确字段值" prop="success_field_value">
                  <el-input v-model="form.success_field_value" placeholder="请输入正确字段值，比如0" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="提示字段" prop="message_field">
                  <el-input v-model="form.message_field" placeholder="请输入提示字段，比如msg" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="数据字段" prop="data_field">
                  <el-input v-model="form.data_field" placeholder="请输入数据字段，比如data" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注" prop="remark">
                  <el-input v-model="form.remark" type="textarea" placeholder="请输入备注" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                  <vxe-grid
                    ref="paramsGrid"
                    row-key
                    max-height="290"
                    border
                    class="sortable-row-demo"
                    :columns="paramsColumn"
                    :data="form.params"
                    :edit-config="{trigger: 'click', mode: 'cell'}"
                    :toolbar-config="tableToolbar1"
                  >
                    <template #toolbar_buttons>
                      <el-button
                        type="primary"
                        icon="el-icon-plus"
                        size="mini"
                        @click="addParam"
                      >添加参数</el-button>
                    </template>
                    <template #paramsdefaultopr="{ row }">
                      <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDeleteParam(row)"
                      >删除
                      </el-button>
                    </template>
                  </vxe-grid>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="请求头配置" name="header" :lazy="true">
          <vxe-grid v-if="activeTab == 'header'"
            ref="headersGrid"
            row-key
            max-height="400"
            border
            class="sortable-row-demo"
            :columns="headersColumn"
            :data="form.headers"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            :toolbar-config="tableToolbar1"
          >
            <template #toolbar_buttons>
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="addHeader"
              >添加请求头</el-button>
            </template>
            <template #auto="{ row }">
              <el-switch
                v-model="row.auto"
                active-color="#13ce66"
                active-value="1"
                inactive-value="0"
                >
              </el-switch>
            </template>
            <template #headersdefaultopr="{ row }">
              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDeleteHeader(row)"
              >删除
              </el-button>
            </template>
          </vxe-grid>
        </el-tab-pane>
        <el-tab-pane label="数据列配置" name="meta" :lazy="true">
          <vxe-grid v-if="activeTab == 'meta'"
            ref="metasGrid"
            row-key
            max-height="400"
            border
            class="sortable-row-demo"
            :columns="metasColumn"
            :data="form.metas"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            :toolbar-config="tableToolbar1"
          >
            <template #toolbar_buttons>
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="mini"
                @click="analysisMeta"
              >解析</el-button>
            </template>
            <template #display_type="{ row }">
              <el-select v-model="row.display_type" placeholder="请选择">
                <el-option label="文本" value=""></el-option>
                <el-option label="图片" value="image"></el-option>
                <el-option label="二维码" value="qrcode"></el-option>
                <el-option label="链接" value="link"></el-option>
              </el-select>
            </template>
            <template #parse_type="{ row }">
              <el-select v-model="row.parse_type" placeholder="请选择">
                <el-option label="无" value=""></el-option>
                <el-option label="自循环" value="self_cycle"></el-option>
                <el-option label="自循环（逗号分割）" value="self_cycle_,"></el-option>
                <el-option label="自循环（分号分割）" value="self_cycle_;"></el-option>
                <el-option label="自循环（json）" value="self_cycle_json"></el-option>
              </el-select>
            </template>
            <template #metasdefaultopr="{ row }">
              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDeleteMeta(row)"
              >删除
              </el-button>
            </template>
          </vxe-grid>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button style="float: left" @click="previewData">预览数据</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="预览数据" :visible.sync="openPreview" width="960px" append-to-body>
      <vxe-grid
        show-overflow
        ref="previewGrid"
        max-height="400"
        border
        :columns="previewColumn"
        :data="previewList"
        :toolbar-config="tableToolbar2"
      >
      </vxe-grid>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closePreview">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getModel, addModel, updateModel, previewModel } from "@/api/core/data/datasource/model";
import { getDatabaseTree } from "@/api/core/data/datasource/database";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Loading } from 'element-ui';
import Sortable from 'sortablejs'

export default {
  name: "add-model",
  components: { Treeselect },
  data() {
    return {
      tableToolbar1: {
        perfect: false,
        zoom: true,
        custom: false,
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      tableToolbar2: {
        perfect: true,
        zoom: true,
        custom: false,
      },
      metasColumn: [
        {field: 'index', title: '序号', width: 50, align: "center"},
        {field: 'name', title: '字段名' },
        {field: 'type', title: '类型', width: 60, formatter: this.typeFormat },
        {field: 'display_type', title: '展示方式', width: 130, slots: {default: "display_type"} },
        {field: 'parse_type', title: '解析方式', width: 180, slots: {default: "parse_type"}},
        {field: 'label', title: '标签', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入标签'}}},
        {field: 'dict', title: '数据字典', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入数据字典'}}},
        //{field: 'sort', title: '排序', editRender: {name: 'input', attrs: {type: 'number', placeholder: '请输入排序'}}},
        {field: 'sort', title: '排序', width: 60, align: "center", slots: {
          default: () => {
            return [
              <span class="drag-btn">
                <i class="vxe-icon--menu"></i>
              </span>
            ]
          }}
        },
        //{type: 'checkbox', width: 60 }
        {field: '', title: '操作',resizable:false, width: 80, fixed: "right", align: "center", slots: {default: 'metasdefaultopr'}}
      ],
      paramsColumn: [
        {field: 'index', title: '序号', width: 80, align: "center"},
        {field: 'name', title: '参数名', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入参数名'}} },
        //{field: 'type', title: '类型', editRender: {name: 'select', options: [{label: '', value: ''}, {label: 'string', value: 'string'}, {label: 'number', value: 'number'}]} },
        //{field: 'label', title: '标签', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入标签'}}},
        {field: 'default_value', title: '默认值', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入默认值'}} },
        {field: 'remark', title: '备注', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入备注'}} },
        //{field: 'sort', title: '排序', editRender: {name: 'input', attrs: {type: 'number', placeholder: '请输入排序'}}},
        {field: 'sort', title: '排序', width: 60, align: "center", slots: {
          default: () => {
            return [
              <span class="drag-btn">
                <i class="vxe-icon--menu"></i>
              </span>
            ]
          }}
        },
        {field: '', title: '操作',resizable:false, width: 80, fixed: "right", align: "center", slots: {default: 'paramsdefaultopr'}}
      ],
      headersColumn: [
        {field: 'index', title: '序号', width: 80, align: "center"},
        {field: 'name', title: '请求头', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入请求头'}} },
        //{field: 'type', title: '类型', editRender: {name: 'select', options: [{label: '', value: ''}, {label: 'string', value: 'string'}, {label: 'number', value: 'number'}]} },
        //{field: 'label', title: '标签', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入标签'}}},
        {field: 'default_value', title: '默认值', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入默认值'}} },
        {field: 'auto', title: '自动传值',resizable:false, width: 120, align: "center", slots: {default: 'auto'}},
        //{field: 'sort', title: '排序', editRender: {name: 'input', attrs: {type: 'number', placeholder: '请输入排序'}}},
        {field: 'sort', title: '排序', width: 60, align: "center", slots: {
          default: () => {
            return [
              <span class="drag-btn">
                <i class="vxe-icon--menu"></i>
              </span>
            ]
          }}
        },
        {field: '', title: '操作',resizable:false, width: 80, fixed: "right", align: "center", slots: {default: 'headersdefaultopr'}}
      ],
      previewColumn: [],
      previewList: [],
      activeTab: 'basic',
      loading: true,
      title: "",
      open: false,
      openPreview: false,
      databaseTree: [],
      form: {
        metas: [],
        params: [],
        headers: []
      },
      rules: {
        name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
        url: [
          { required: true, message: "请求地址不能为空", trigger: "blur" }
        ],
        method: [
          { required: true, message: "请选择请求方法", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    getDatabaseTree().then(response => {
      if (response.code == 0) {
        this.databaseTree = response.data
      }
    })
  },
  methods: {
    rowDropParams () {
      this.$nextTick(() => {
        if (this.sortableParams) {
          return
        }
        const xTable = this.$refs.paramsGrid
        this.sortableParams = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
          handle: '.drag-btn',
          onEnd: ({ newIndex, oldIndex }) => {
            const currRow = this.form.params.splice(oldIndex, 1)[0]
            this.form.params.splice(newIndex, 0, currRow)
            for (let i = 0; i < this.form.params.length; i++) {
              this.form.params[i].sort = i + 1
            }
          }
        })
      })
    },
    rowDropHeaders () {
      this.$nextTick(() => {
        if (this.sortableHeaders) {
          return
        }
        const xTable = this.$refs.headersGrid
        this.sortableHeaders = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
          handle: '.drag-btn',
          onEnd: ({ newIndex, oldIndex }) => {
            const currRow = this.form.headers.splice(oldIndex, 1)[0]
            this.form.headers.splice(newIndex, 0, currRow)
            for (let i = 0; i < this.form.headers.length; i++) {
              this.form.headers[i].sort = i + 1
            }
          }
        })
      })
    },
    rowDropMetas () {
      this.$nextTick(() => {
        if (this.sortableMetas) {
          return
        }
        const xTable = this.$refs.metasGrid
        this.sortableMetas = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
          handle: '.drag-btn',
          onEnd: ({ newIndex, oldIndex }) => {
            const currRow = this.form.metas.splice(oldIndex, 1)[0]
            this.form.metas.splice(newIndex, 0, currRow)
            for (let i = 0; i < this.form.metas.length; i++) {
              this.form.metas[i].sort = i + 1
            }
          }
        })
      })
    },
    normalizerDatabaseNode(e) {
      e.label = e.name
      return e
    },
    cancel() {
      this.previewColumn = []
      this.previewList = []
      this.open = false;
      this.reset();
    },
    reset() {
      this.form = {
        id: undefined,
        pid: 0,
        type: '4',
        mode: '0',
        name: '',
        url: '',
        method: '',
        internal: '0',
        timeout: undefined,
        success_field: '',
        success_field_value: '',
        message_field: '',
        data_field: '',
        ext: '',
        remark: '',
        metas: [],
        params: [],
        headers: []
      };
      this.resetForm("form");
    },
    add(mode, pid) {
      this.reset();
      this.form.mode = mode
      this.form.pid = pid
      this.activeTab = 'basic'
      this.open = true;
      this.title = "添加API数据集（" + (mode == '0' ? '实时' : '抽取') + '）';
    },
    update(id) {
      this.reset();
      getModel(id).then(response => {
        //this.form = response.data;
        let data = response.data
        if (data) {
          this._sql_ = data.sql
          for (let key in data) {
            this.form[key] = data[key]
          }

          if (data.ext) {
            const ext = JSON.parse(data.ext)
            this.form.internal = ext.internal || '0'
            this.form.timeout = ext.timeout || undefined
            this.form.success_field = ext.success_field || ''
            this.form.success_field_value = ext.success_field_value || ''
            this.form.message_field = ext.message_field || ''
            this.form.data_field = ext.data_field || ''
          }

          for (let i = 0; i < this.form.metas.length; i++) {
            const m = this.form.metas[i]
            m.index = i + 1
            m.sort = i + 1
          }

          for (let i = 0; i < this.form.params.length; i++) {
            const p = this.form.params[i]
            p.index = i + 1
            p.sort = i + 1
          }

          for (let i = 0; i < this.form.headers.length; i++) {
            const h = this.form.headers[i]
            h.index = i + 1
            h.sort = i + 1
          }

          this.activeTab = 'basic'
          this.open = true;
          this.title = "修改API数据集（" + (data.mode == '0' ? '实时' : '抽取') + '）';
        }
      });
    },
    submitForm: function() {
      //console.log(this.form)
      this.$refs["form"].validate(valid => {
        if (valid) {
          /*if (!this.form.metas || this.form.metas.length == 0 || this._sql_ != this.form.sql) {
            this.analysisMeta().then(() => {
              this.doSubmitForm()
            })
          } else {*/
            this.doSubmitForm()
          //}
        }
      });
    },
    doSubmitForm: function() {
      if (this.form.timeout || this.form.success_field || this.form.success_field_value || this.form.message_field || this.form.data_field) {
        this.form.ext = JSON.stringify({
          internal: this.form.internal,
          timeout: this.form.timeout,
          success_field: this.form.success_field,
          success_field_value: this.form.success_field_value,
          message_field: this.form.message_field,
          data_field: this.form.data_field
        })
      }
      if (this.form.id != undefined) {
        updateModel(this.form).then(response => {
          if (response.code === 0) {
            this.msgSuccess("修改成功");
            this.previewColumn = []
            this.previewList = []
            this.open = false;
            this.$emit('change', response.data)
          } else {
            this.msgError(response.msg);
          }
        });
      } else {
        addModel(this.form).then(response => {
          if (response.code === 0) {
            this.msgSuccess("新增成功");
            this.previewColumn = []
            this.previewList = []
            this.open = false;
            this.$emit('change', response.data)
          } else {
            this.msgError(response.msg);
          }
        });
      }
    },
    typeFormat({row}) {
      if (row.type == 'number') {
        return '数值'
      } else if (row.type == 'object') {
        return '对象'
      } else {
        return '文本'
      }
    },
    previewData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loadingInstance = Loading.service({
            text: '拼命加载中',
            background: 'rgba(0,0,0,0.2)',
            spinner: 'el-icon-loading'
          });
          const pform = JSON.parse(JSON.stringify(this.form))
          if (this.form.internal || this.form.timeout || this.form.success_field || this.form.success_field_value || this.form.message_field || this.form.data_field) {
            pform.ext = JSON.stringify({
              internal: this.form.internal,
              timeout: this.form.timeout,
              success_field: this.form.success_field,
              success_field_value: this.form.success_field_value,
              message_field: this.form.message_field,
              data_field: this.form.data_field
            })
          }
          previewModel(pform).then(response => {
            this.loadingInstance.close();
            if (response.code === 0) {
              if (response.data && response.data.length > 0) {
                const previewColumn = []
                for (let i = 0; i < this.form.metas.length; i++) {
                  const m = this.form.metas[i]
                  m.index = i + 1
                  m.sort = i + 1
                  previewColumn.push({
                    field: m.name,
                    title: m.name,
                    minWidth: 120
                  })
                }
                if (previewColumn.length == 0) {
                  const d = response.data[0]
                  for (let k in d) {
                    previewColumn.push({
                      field: k,
                      title: k,
                      minWidth: 150
                    })
                  }
                }
                this.previewColumn = previewColumn
                this.previewList = response.data
                this.openPreview = true
              } else {
                this.previewColumn = []
                this.previewList = []
                this.openPreview = true
              }
            } else {
              this.msgError(response.msg);
            }
          }).catch((e) => {
            this.loadingInstance.close();
          });
        } else {
          this.activeTab = 'basic'
        }
      });
    },
    analysisMeta() {
      return new Promise((resolve, reject) => {
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.loadingInstance = Loading.service({
              text: '拼命加载中',
              background: 'rgba(0,0,0,0.2)',
              spinner: 'el-icon-loading'
            });
            const pform = JSON.parse(JSON.stringify(this.form))
            if (this.form.internal || this.form.timeout || this.form.success_field || this.form.success_field_value || this.form.message_field || this.form.data_field) {
              pform.ext = JSON.stringify({
                internal: this.form.internal,
                timeout: this.form.timeout,
                success_field: this.form.success_field,
                success_field_value: this.form.success_field_value,
                message_field: this.form.message_field,
                data_field: this.form.data_field
              })
            }
            previewModel(pform).then(response => {
              this.loadingInstance.close();
              if (response.code === 0) {
                if (response.data && response.data.length > 0) {
                  const metas = []
                  let index = 1
                  const d = response.data[0]
                  for (let k in d) {
                    const v = d[k]
                    let oldMeta = null
                    for (let i = 0; i < this.form.metas.length; i++) {
                      const m = this.form.metas[i]
                      if (m.name == k) {
                        oldMeta = m
                        break
                      }
                    }
                    metas.push({
                      index: index,
                      name: k,
                      type: typeof v,
                      label: oldMeta && oldMeta.label || '',
                      dict: oldMeta && oldMeta.dict || '',
                      sort: index//oldMeta && oldMeta.sort || 0
                    })
                    index++
                  }
                  this.form.metas = metas
                } else {
                  this.form.metas = []
                }
                resolve()
              } else {
                this.msgError(response.msg);
                reject()
              }
            }).catch((e) => {
              this.loadingInstance.close();
              reject()
            });
          } else {
            this.activeTab = 'basic'
            reject()
          }
        })
      })
    },
    closePreview() {
      this.openPreview = false
    },
    handleDeleteMeta(row) {
      this.$confirm('确定删除序号为"' + row.index + '"的数据列?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const metas = []
        let index = 1
        for (let i = 0; i < this.form.metas.length; i++) {
          const m = this.form.metas[i]
          if (m.index != row.index) {
            m.index = index++
            m.sort = m.index
            metas.push(m)
          }
        }
        this.form.metas = metas
      }).catch(function() {});
    },
    addParam() {
      this.form.params.push({
        index: this.form.params.length + 1,
        name: 'param' + (this.form.params.length + 1),
        default_value: '',
        sort: 0
      })
    },
    addHeader() {
      this.form.headers.push({
        index: this.form.headers.length + 1,
        name: 'header' + (this.form.headers.length + 1),
        default_value: '',
        sort: 0
      })
    },
    handleDeleteParam(row) {
      this.$confirm('确定删除序号为"' + row.index + '"的参数?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const params = []
        let index = 1
        for (let i = 0; i < this.form.params.length; i++) {
          const p = this.form.params[i]
          if (p.index != row.index) {
            p.index = index++
            p.sort = p.index
            params.push(p)
          }
        }
        this.form.params = params
      }).catch(function() {});
    },
    handleDeleteHeader(row) {
      this.$confirm('确定删除序号为"' + row.index + '"的请求头?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const headers = []
        let index = 1
        for (let i = 0; i < this.form.headers.length; i++) {
          const h = this.form.headers[i]
          if (h.index != row.index) {
            h.index = index++
            h.sort = h.index
            headers.push(h)
          }
        }
        this.form.headers = headers
      }).catch(function() {});
    }
  },
  watch: {
    open(val) {
      if (val) {
        this.rowDropParams()
      } else {
        if (this.sortableParams) {
          this.sortableParams.destroy()
          this.sortableParams = null
        }
        if (this.sortableHeaders) {
          this.sortableHeaders.destroy()
          this.sortableHeaders = null
        }
        if (this.sortableMetas) {
          this.sortableMetas.destroy()
          this.sortableMetas = null
        }
      }
    },
    activeTab(val) {
      if (val == 'meta') {
        this.rowDropMetas()
      } else {
        if (this.sortableMetas) {
          this.sortableMetas.destroy()
          this.sortableMetas = null
        }
      }
      if (val == 'header') {
        this.rowDropHeaders()
      } else {
        if (this.sortableHeaders) {
          this.sortableHeaders.destroy()
          this.sortableHeaders = null
        }
      }
    }
  }
};
</script>

<style scoped>
.sortable-row-demo .drag-btn {
  cursor: move;
  font-size: 12px;
}
.sortable-row-demo .vxe-body--row.sortable-ghost,
.sortable-row-demo .vxe-body--row.sortable-chosen {
  background-color: #dfecfb;
}
</style>
