<template>
    <div :style="'height:' + height + 'px'">
        <div
            style="
                margin-top: -10px;
                padding: 0px 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            "
        >
            <el-button
                type="text"
                icon="el-icon-plus"
                size="small"
                @click="() => handleAddCategory(0, '1')"
                >添加分组</el-button
            >
            <!--<el-button type="text" icon="el-icon-plus" size="small" @click="() => handleAddModel(0)">添加数据集</el-button>-->
            <el-dropdown-ex
                trigger="click"
                :stopPropagationOnClick="true"
                @command="handleCommand"
            >
                <span
                    class="el-dropdown-link"
                    style="font-size: 12px; color: #1890ff"
                >
                    <i class="el-icon-plus"></i>
                    <span style="margin-left: 1px">添加数据集</span>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="makeCommand('addSqlModel')"
                        >SQL数据集</el-dropdown-item
                    >
                    <el-dropdown-item :command="makeCommand('addApiModel')"
                        >API数据集</el-dropdown-item
                    >
                    <el-dropdown-item v-if="mode == '1'" :command="makeCommand('addExcelModel')"
                        >Excel数据集</el-dropdown-item
                    >
                </el-dropdown-menu>
            </el-dropdown-ex>
        </div>
        <div style="padding: 0px 10px">
            <el-input
                v-model="searchKey"
                placeholder="搜索分组和数据集"
                prefix-icon="el-icon-search"
                size="small"
                clearable
            />
        </div>
        <div
            :style="
                'height:' + treeHeight + 'px; margin-top:10px; overflow-y:auto;'
            "
        >
            <el-tree
                ref="tree"
                :data="data"
                :props="propsTree"
                node-key="id"
                :default-expanded-keys="expandedKeys"
                :highlight-current="true"
                :filter-node-method="filterNode"
                @node-click="handleTreeNodeClick"
                @node-expand="handleTreeNodeExpand"
                @node-collapse="handleTreeNodeCollapse"
            >
                <div class="custom-tree-node" slot-scope="{ node, data }">
                    <img
                        v-if="data.type == '2'"
                        :src="imgs.folder"
                        class="custom-tree-node-img"
                    />
                    <img
                        v-else-if="data.type == '3'"
                        :src="imgs.table"
                        class="custom-tree-node-img"
                    />
                    <img
                        v-else-if="data.type == '4'"
                        :src="imgs.table"
                        class="custom-tree-node-img"
                    />
                    <img
                        v-else-if="data.type == '5'"
                        :src="imgs.table"
                        class="custom-tree-node-img"
                    />
                    <div
                        class="el-tree-node__label custom-tree-node-label"
                        :title="node.label"
                    >
                        {{ node.label }}
                    </div>
                    <div class="custom-tree-node-button">
                        <el-dropdown-ex
                            trigger="click"
                            :stopPropagationOnClick="true"
                            @command="handleCommand"
                            @visible-change="
                                (e) => handleDropdownVisibleChange(e, data)
                            "
                        >
                            <span class="el-dropdown-link">
                                <i class="el-icon-more"></i>&nbsp;
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-if="data.type == '1'"
                                    icon="el-icon-plus"
                                    :command="
                                        makeCommand('addChildSqlModel', data)
                                    "
                                    >SQL数据集</el-dropdown-item
                                >
                                <el-dropdown-item
                                    v-if="data.type == '1'"
                                    icon="el-icon-plus"
                                    :command="
                                        makeCommand('addChildApiModel', data)
                                    "
                                    >API数据集</el-dropdown-item
                                >
                                <el-dropdown-item
                                    v-if="data.type == '1' && mode == '1'"
                                    icon="el-icon-plus"
                                    :command="
                                        makeCommand('addChildExcelModel', data)
                                    "
                                    >Excel数据集</el-dropdown-item
                                >
                                <el-dropdown-item
                                    v-if="data.type == '1'"
                                    icon="el-icon-edit"
                                    :command="makeCommand('rename', data)"
                                    divided
                                    >重命名</el-dropdown-item
                                >
                                <el-dropdown-item
                                    v-else
                                    icon="el-icon-edit"
                                    :command="makeCommand('rename', data)"
                                    >重命名</el-dropdown-item
                                >
                                <el-dropdown-item
                                    icon="el-icon-delete"
                                    :command="makeCommand('delete', data)"
                                    >删除</el-dropdown-item
                                >
                                <el-dropdown-item
                                    v-if="data.type != '1'"
                                    icon="el-icon-right"
                                    :command="makeCommand('moveto', data)"
                                    >移动到</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown-ex>
                    </div>
                </div>
            </el-tree>
        </div>

        <el-dialog
            :title="titleCategory"
            :visible.sync="openCategory"
            width="500px"
            :close-on-click-modal="true"
            append-to-body
        >
            <el-form
                ref="formCategory"
                :model="formCategory"
                :rules="rulesCategory"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="formCategory.name"
                                placeholder="名称"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitCategoryForm"
                    >确 定</el-button
                >
                <el-button @click="() => (openCategory = false)"
                    >取 消</el-button
                >
            </div>
        </el-dialog>

        <tree-select
            ref="selectGroup"
            v-model="selectedGroup"
            :getDataList="listGroup"
            @change="handleSelectedGroupChange"
        >
            <div></div>
        </tree-select>
    </div>
</template>

<script>
import {
    listModel,
    getModel,
    addModel,
    updateModel,
    delModel,
    previewModel,
    execModel,
} from "@/api/core/data/datasource/model";

export default {
    name: "model-tree",
    props: {
        mode: {
            type: String,
            default: () => {
                return "0";
            },
        },
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
        height: {
            type: Number,
            default: () => {
                return 100;
            },
        },
    },
    data() {
        return {
            imgs: {
                //folder: require("@/assets/image/folder1.png"),
                table: require("@/assets/image/table.png"),
            },
            searchKey: "",
            dataTree: [],
            expandedKeys: [],
            propsTree: {
                label: "name",
            },
            openCategory: false,
            titleCategory: "",
            formCategory: {},
            rulesCategory: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            selectedGroup: undefined,
        };
    },
    methods: {
        getList() {
            this.$emit("refresh", this.mode);
        },

        handleAddCategory(pid, type) {
            if (type == "1") {
                this.titleCategory = "添加分组";
            } else if (type == "2") {
                this.titleCategory = "添加业务包";
            } else {
                this.titleCategory = "";
            }
            this.formCategory = {
                pid: pid,
                type: type,
                mode: this.mode,
                name: "",
            };
            this.resetForm("formCategory");
            this.openCategory = true;
        },

        handleRenameCategory(id, type, name) {
            this.titleCategory = "重命名";
            this.formCategory = {
                id: id,
                type: type,
                mode: this.mode,
                name: name,
            };
            this.resetForm("formCategory");
            this.openCategory = true;
        },

        submitCategoryForm() {
            this.$refs["formCategory"].validate((valid) => {
                if (valid) {
                    if (this.formCategory.id != undefined) {
                        updateModel(this.formCategory).then((response) => {
                            if (response.code === 0) {
                                this.openCategory = false;
                                this.getList();
                                this.$emit("rename", this.formCategory);
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    } else {
                        addModel(this.formCategory).then((response) => {
                            if (response.code === 0) {
                                this.openCategory = false;
                                this.getList();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },

        handleAddModel(pid, type) {
            this.$emit("add-model", this.mode, pid, type);
        },

        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },

        handleTreeNodeClick(e) {
            this.currentNode = e;
            this.$emit("node-click", e);
        },

        handleTreeNodeExpand(e) {
            let index = -1;
            for (let i = 0; i < this.expandedKeys.length; i++) {
                if (this.expandedKeys[i] == e.id) {
                    index = i;
                    break;
                }
            }
            if (index == -1) {
                this.expandedKeys.push(e.id);
            }
        },

        handleTreeNodeCollapse(e) {
            let index = -1;
            for (let i = 0; i < this.expandedKeys.length; i++) {
                if (this.expandedKeys[i] == e.id) {
                    index = i;
                    break;
                }
            }
            if (index >= 0) {
                this.expandedKeys.splice(index, 1);
            }
        },

        handleDropdownVisibleChange(e, data) {
            this.currentNode = data;
            this.$refs.tree.setCurrentNode(data);
        },

        handleCommand(cmd) {
            if (cmd.cmd === "addCategory1") {
                this.handleAddCategory(cmd.item.id, "1");
            } else if (cmd.cmd === "addCategory2") {
                this.handleAddCategory(cmd.item.id, "2");
            } else if (cmd.cmd == "addSqlModel") {
                this.handleAddModel(0, "3");
            } else if (cmd.cmd == "addApiModel") {
                this.handleAddModel(0, "4");
            } else if (cmd.cmd == "addExcelModel") {
                this.handleAddModel(0, "5");
            } else if (cmd.cmd == "addChildSqlModel") {
                this.handleAddModel(cmd.item.id, "3");
            } else if (cmd.cmd == "addChildApiModel") {
                this.handleAddModel(cmd.item.id, "4");
            } else if (cmd.cmd == "addChildExcelModel") {
                this.handleAddModel(cmd.item.id, "5");
            } else if (cmd.cmd === "rename") {
                this.handleRenameCategory(
                    cmd.item.id,
                    cmd.item.type,
                    cmd.item.name
                );
            } else if (cmd.cmd === "delete") {
                this.handleDelete(cmd.item);
            } else if (cmd.cmd === "moveto") {
                this.handleMoveTo(cmd.item);
            }
        },

        makeCommand(cmd, item) {
            return {
                cmd: cmd,
                item: item,
            };
        },

        handleDelete(data) {
            let title = "";
            if (data.children && data.children.length > 0) {
                title =
                    "该分组内的所有数据集将无法找回，确定删除" +
                    data.name +
                    "?";
            } else {
                title = "确定删除" + data.name + "?";
            }
            const ids = data.id;
            this.$confirm(title, "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delModel(ids);
                })
                .then(() => {
                    this.getList();
                    this.$emit("delete", data);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        handleMoveTo(data) {
            this.moveToData = data;
            this.$refs.selectGroup.open(true);
        },

        listGroup() {
            const list = [];
            for (let i = 0; i < this.data.length; i++) {
                const d = this.data[i];
                if (d.type == "1" && d.id != this.moveToData.pid) {
                    list.push({
                        id: d.id,
                        name: d.name,
                    });
                }
            }
            return new Promise((resolve, reject) => {
                resolve({
                    code: 0,
                    data: {
                        list: list,
                    },
                });
            });
        },

        handleSelectedGroupChange(value) {
            updateModel({
                id: this.moveToData.id,
                pid: value,
            }).then((response) => {
                if (response.code === 0) {
                    this.getList();
                } else {
                    this.msgError(response.msg);
                }
            });
        },
    },
    watch: {
        searchKey(val) {
            this.$refs.tree.filter(val);
        },
        data(val) {
            if (this.searchKey) {
                this.$nextTick(() => {
                    this.$refs.tree.filter(this.searchKey);
                });
            }
            if (this.currentNode) {
                this.$nextTick(() => {
                    this.$refs.tree.setCurrentNode(this.currentNode);
                });
            }
        },
    },
    computed: {
        treeHeight() {
            return this.height - 33 - 33 - 10 - 4;
        },
    },
};
</script>

<style scoped>
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
}
.custom-tree-node {
    width: 100%;
    display: flex;
    align-items: center;
    height: 26px;
    padding-right: 5px;
}
.custom-tree-node-img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}
.custom-tree-node-label {
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-tree-node-button {
    display: none;
}
.custom-tree-node:hover .custom-tree-node-button {
    display: inline-block;
}
.el-dropdown-link:hover {
    cursor: pointer;
}
</style>