<template>
    <div style="display: flex; flex-direction: column; flex: 1">
        <div class="main-body-header">
            <div>{{ data.name }}</div>
   
            <div>
                 <el-button
                 v-if="data.openfrom=='formdesign'"
                    type="primary"
                    icon="el-icon-check"
                    size="mini"
                    @click="handleSelectModel"
                    >确认选择</el-button 
                >

                <el-button
                    type="primary"
                    icon="el-icon-edit"
                    size="mini"
                    @click="handleEditModel"
                    >编辑</el-button 
                >
            </div>

        </div>
        <div class="main-body-body">
            <div class="tabs">
                <div class="tabs-header">
                    <div
                        :class="
                            activeTab == 'preview'
                                ? 'tabs-header-item tabs-header-item-active'
                                : 'tabs-header-item'
                        "
                        @click="() => (activeTab = 'preview')"
                    >
                        数据预览
                    </div>
                    <div
                        :class="
                            activeTab == 'metas'
                                ? 'tabs-header-item tabs-header-item-active'
                                : 'tabs-header-item'
                        "
                        @click="() => (activeTab = 'metas')"
                    >
                        数据字段
                    </div>
                    <div
                        v-if="data.mode == '1'"
                        :class="
                            activeTab == 'updateData'
                                ? 'tabs-header-item tabs-header-item-active'
                                : 'tabs-header-item'
                        "
                        @click="() => (activeTab = 'updateData')"
                    >
                        数据更新
                    </div>
                    <div
                        :class="
                            activeTab == 'info'
                                ? 'tabs-header-item tabs-header-item-active'
                                : 'tabs-header-item'
                        "
                        @click="() => (activeTab = 'info')"
                    >
                        基本信息
                    </div>
                </div>
                <div class="tabs-body">
                    <div v-if="activeTab == 'preview'" class="tabs-panel">
                        <el-button
                            v-if="data.mode == '1' && data.type != '5' && data.extract_table == ''"
                            type="primary"
                            icon="el-icon-refresh"
                            size="small"
                            @click="handleUpdateData"
                            >更新</el-button
                        >
                        <!-- <vxe-grid
                            v-else
                            :max-height="panelContentHeight + 'px'"
                            show-overflow
                            ref="previewGrid"
                            border
                            :columns="previewColumn"
                            :data="previewList"
                        >
                        </vxe-grid> -->
                        <jf-table
                            v-else
                            :max-height="panelContentHeight"
                            :pager="false"
                            :operate="false"
                            :checkbox="false"
                            ref="previewGrid"
                            :columns="previewColumn"
                            :data="previewList"
                        >
                        </jf-table>
                    </div>
                    <div v-if="activeTab == 'metas'" class="tabs-panel">
                        <!-- <vxe-grid
                            :max-height="panelContentHeight + 'px'"
                            ref="metasGrid"
                            border
                            :columns="metasColumn"
                            :data="data.metas"
                        >
                        </vxe-grid> -->
                        <jf-table
                            :max-height="panelContentHeight"
                            :pager="false"
                            :operate="false"
                            :checkbox="false"
                            ref="metasGrid"
                            :columns="metasColumn"
                            :data="data.metas"
                        >
                        </jf-table>
                    </div>
                    <div v-if="activeTab == 'updateData'" class="tabs-panel">
                        <el-button v-if="data.mode == '1' && data.type != '5'"
                            type="primary"
                            icon="el-icon-refresh"
                            size="small"
                            @click="handleUpdateData"
                            >更新</el-button
                        >
                        <el-descriptions
                            v-if="updateTime"
                            :column="1"
                            style="margin-top: 10px"
                        >
                            <el-descriptions-item label="上次更新时间"
                                >{{ updateTime }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                    updateSeconds
                                }}</el-descriptions-item
                            >
                            <el-descriptions-item label="上次更新结果">{{
                                data.extract_result
                            }}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-if="activeTab == 'info'" class="tabs-panel">
                        <el-descriptions :column="1">
                            <el-descriptions-item label="ID">{{
                                data.id
                            }}</el-descriptions-item>
                            <el-descriptions-item label="名称">{{
                                data.name
                            }}</el-descriptions-item>
                            <el-descriptions-item label="来源">{{
                                data.database_name
                            }}</el-descriptions-item>
                            <el-descriptions-item
                                v-if="data.extract_table != ''"
                                label="存储"
                                >{{ data.local_database_name }}({{
                                    data.extract_table
                                }})</el-descriptions-item
                            >
                            <el-descriptions-item label="备注">{{
                                data.remark
                            }}</el-descriptions-item>
                        </el-descriptions>
                        <div v-if="data.type != '5'" style="font-size: 14px; color: #606266">
                            参数：
                        </div>
                        <div v-if="data.type != '5'" style="margin-top: 15px">
                            <!-- <vxe-grid
                                :max-height="
                                    panelContentHeight - 96 - 16 - 15 + 'px'
                                "
                                ref="paramsGrid"
                                border
                                :columns="paramsColumn"
                                :data="data.params"
                            >
                            </vxe-grid> -->
                            <jf-table
                                :max-height="panelContentHeight - 96 - 16 - 15"
                                :pager="false"
                                :operate="false"
                                :checkbox="false"
                                ref="paramsGrid"
                                :columns="paramsColumn"
                                :data="data.params"
                            >
                            </jf-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    listModel,
    getModel,
    addModel,
    updateModel,
    delModel,
    previewModel,
    execModel,
    updateData,
} from "@/api/core/data/datasource/model";

export default {
    name: "model-detail",
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
        height: {
            type: Number,
            default: () => {
                return 100;
            },
        },
    },
    data() {
        return {
            activeTab: "preview",
            metasColumn: [
                { field: "index", title: "序号", width: 100, fixed: "left" },
                { field: "name", title: "字段名" },
                { field: "type", title: "类型", formatter: this.typeFormat },
                { field: "label", title: "标签" },
                { field: "dict", title: "数据字典" },
                //{field: 'sort', title: '排序'},
            ],
            paramsColumn: [
                { field: "index", title: "序号", width: 100, fixed: "left" },
                { field: "name", title: "参数名" },
                { field: "default_value", title: "默认值" },
                { field: "remark", title: "备注" },
                //{field: 'sort', title: '排序'},
            ],
            previewColumn: [],
            previewList: [],
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        handleEditModel() {
            this.$emit("edit-model");
        },
        handleSelectModel(){
            this.$emit("handleSelectModel",this.data);
        },

        typeFormat({ row }) {
            if (row.type == "number") {
                return "数值";
            } else if (row.type == "object") {
                return "对象";
            } else {
                return "文本";
            }
        },

        refresh() {
            this.previewColumn = [];
            this.previewList = [];

            const loading = this.$loading({
                lock: true,
                //text: '正在加载数据',
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            getModel(this.data.id)
                .then((response) => {
                    if (response.code == 0) {
                        for (let k in response.data) {
                            this.data[k] = response.data[k];
                        }

                        this.$emit("refresh-model", this.data);

                        for (let i = 0; i < this.data.params.length; i++) {
                            this.data.params[i].index = i + 1;
                        }
                        const previewColumn = [];
                        for (let i = 0; i < this.data.metas.length; i++) {
                            const m = this.data.metas[i];
                            m.index = i + 1;
                            previewColumn.push({
                                field: m.name,
                                title: m.label||m.name,
                                minWidth: 120,
                            });
                        }
                        this.previewColumn = previewColumn;
                        const maxCount = 1000;
                        execModel(
                            this.data.id,
                            JSON.stringify({ __max_count__: maxCount })
                        )
                            .then((response) => {
                                loading.close();
                                if (response.code == 0) {
                                    if (
                                        previewColumn.length == 0 &&
                                        response.data.length > 0
                                    ) {
                                        const previewColumn = [];
                                        const d = response.data[0];
                                        for (let k in d) {
                                            previewColumn.push({
                                                field: k,
                                                title: k,
                                                minWidth: 120,
                                            });
                                        }
                                        this.previewColumn = previewColumn;
                                    }
                                    this.previewList = response.data;
                                }
                            })
                            .catch((err) => {
                                loading.close();
                            });
                    }
                })
                .catch((err) => {
                    loading.close();
                });
        },

        handleUpdateData() {
            const that = this;
            let loading = null;
            const id = this.data.id;
            this.$confirm("确定更新数据吗？", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    loading = that.$loading({
                        lock: true,
                        //text: '正在加载数据',
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.1)",
                    });
                    return updateData(id, "{}");
                })
                .then(() => {
                    if (loading) {
                        loading.close();
                    }
                    this.msgSuccess("更新成功");
                    this.refresh();
                })
                .catch((err) => {
                    if (loading) {
                        loading.close();
                    }
                    console.log(err);
                });
        },
    },
    watch: {
        data(val) {
            this.refresh();
        },
    },
    computed: {
        panelContentHeight() {
            return this.height - 80 - 30;
        },
        updateTime() {
            if (!this.data.extract_start_time) {
                return "";
            }
            return this.XEUtils.toDateString(
                new Date(this.data.extract_start_time * 1000),
                "yyyy-MM-dd HH:mm:ss"
            );
        },
        updateSeconds() {
            if (this.data.extract_end_time >= this.data.extract_start_time) {
                return (
                    "耗时" +
                    (this.data.extract_end_time -
                        this.data.extract_start_time) +
                    "秒"
                );
            } else {
                return "";
            }
        },
    },
};
</script>

<style scoped>
.main-body-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: bold;
}
.main-body-body {
    flex: 1;
}
.tabs {
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
.tabs-header {
    height: 40px;
    background-color: #f2f4f7;
    display: flex;
}
.tabs-header-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    font-size: 13px;
    color: #333;
    font-weight: bold;
    cursor: pointer;
}
.tabs-header-item-active {
    background-color: #fff;
    border-top: solid 2px #1890ff;
    border-bottom: solid 2px #fff;
    color: #1890ff;
}
.tabs-header-item:hover {
    color: #1890ff;
}
.tabs-body {
    flex: 1;
}
.tabs-panel {
    height: 100%;
    padding: 15px;
}
</style>